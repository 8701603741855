import mixpanel from 'mixpanel-browser';
import { isProduction, mixpanelApiHost, mixpanelToken } from '../services/env';
import { useEffect } from 'react';

function checkShouldTrack(...args) {
  if (!isProduction) {
    console.log('Mixpanel: ', ...args);
    return false;
  }

  if (!mixpanelToken) {
    return false;
  }

  return true;
}

function initTracking() {
  if (!checkShouldTrack('init')) {
    return;
  }

  mixpanel.init(mixpanelToken, {
    api_host: mixpanelApiHost,
    debug: process.env.NODE_ENV !== 'production',
    track_pageview: true,
    ip: true,
  });
}

function useTracking() {
  useEffect(() => {
    initTracking();
  }, []);

  return null;
}

function trackEvent(eventName, values = {}) {
  if (!checkShouldTrack('trackEvent', eventName, values)) {
    return;
  }

  try {
    mixpanel.track(eventName, values);
  } catch (e) {
    // no-op
  }
}

function identifyUser(userId) {
  if (!checkShouldTrack('identifyUser', userId)) {
    return;
  }

  try {
    mixpanel.identify(userId);
  } catch (e) {
    // no-op
  }
}

function updateUser(values = {}) {
  if (!checkShouldTrack('updateUser', values)) {
    return;
  }

  try {
    mixpanel.people.set(values);
  } catch (e) {
    // no-op
  }
}

function setUser(values = {}) {
  if (!checkShouldTrack('setUser', values)) {
    return;
  }

  try {
    mixpanel.people.set_once(values);
  } catch (e) {
    // no-op
  }
}

function logout() {
  if (!checkShouldTrack('logout')) {
    return;
  }

  try {
    mixpanel.reset();
  } catch (e) {
    // no-op
  }
}

function trackPageview() {
  if (!checkShouldTrack('trackPageview')) {
    return;
  }

  try {
    mixpanel.track_pageview();
  } catch (e) {
    // no-op
  }
}

const tracking = {
  identifyUser,
  trackEvent,
  setUser,
  updateUser,
  logout,
  trackPageview,
};

export { useTracking, tracking };
