import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import usePrevious from '../../hooks/usePrevious';

const ScrollToTop = () => {
  const location = useLocation();
  const previousLocation = usePrevious(location);

  useEffect(() => {
    if (location.pathname !== previousLocation?.pathname) {
      window.scrollTo(0, 0);
      return;
    }

    if (location.hash) {
      const hash = location.hash.toString();
      let element;
      if (/^[0-9]/.test(hash.substring(1))) {
        element = document.getElementById(hash.substring(1));
      } else {
        element = document.querySelector(hash);
      }

      if (element) {
        element.scrollIntoView();
      }
    }
  }, [location]);

  return null;
};

export default ScrollToTop;
