const protectedSubdomains = [
  'app',
  'dashboard',
  'admin',
  'staging',
  'staging-app',
  'api',
];

/**
 * Returns the subdomain or custom domain of the store, or null if it's not a store
 * @param {string} hostname
 * @returns {string} subdomain or custom domain or null
 */
export function getStoreDomain(hostname) {
  if (
    hostname.includes('.vercel.app') ||
    hostname.includes('checkoutpage-web') ||
    hostname.includes('.ngrok.app') ||
    hostname.includes('.ngrok-free.app')
  ) {
    return null;
  }

  if (
    hostname.endsWith('localhost') ||
    hostname === 'staging-app.checkoutpage.co'
  ) {
    const parts = hostname.split('.');

    if (parts.length < 2) {
      return null;
    }

    if (protectedSubdomains.includes(parts[0])) {
      return null;
    }

    // if the hostname contains 'checkoutpage-web' or 'staging', return null
    if (parts[0].includes('checkoutpage-web') || parts[0].includes('staging')) {
      return null;
    }

    return parts[0];
  }

  // if this is a custom domain, return it as is
  if (!hostname.endsWith(process.env.REACT_APP_ROOT_DOMAIN)) {
    return hostname;
  }

  const parts = hostname.split('.');

  if (parts.length < 3) {
    return null;
  }

  if (protectedSubdomains.includes(parts[0])) {
    return null;
  }

  // if the hostname contains 'checkoutpage-web' or 'staging', return null
  if (parts[0].includes('checkoutpage-web') || parts[0].includes('staging')) {
    return null;
  }

  return parts[0];
}
