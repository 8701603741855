import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';

import Routes from '../startup/routes';

import ScrollToTop from '../components/ScrollToTop';

import '../styles/index.css';
import '../styles/vars.css';
import '../styles/settings.css';
import '../styles/lexical.css';
import '../styles/store.css';

import { SWRConfig } from 'swr';

export default function App() {
  return (
    <SWRConfig>
      <BrowserRouter>
        <CompatRouter>
          <ScrollToTop />
          <Routes />
        </CompatRouter>
      </BrowserRouter>
    </SWRConfig>
  );
}
