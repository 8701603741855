import React from 'react';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import App from './App';

if (module.hot) {
  module.hot.accept();
}

ReactGA.initialize('G-608VH3FZ42', {
  testMode: process.env.NODE_ENV !== 'production',
});

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://92671e6d5dfd45b59fc375bc1973a7d9@sentry.io/304726',
    integrations: [new BrowserTracing()],
    ignoreErrors: [
      'TypeError: undefined is not a constructor',
      'ChunkLoadError',
      'TypeError: Load failed',
      'TypeError: Failed to fetch',
      'TypeError: cancelled',
      'Non-Error promise rejection captured with value:',
      "Your card's security code is incomplete.",
      'globalThis is not defined',
      'Loading CSS chunk',
    ],
  });
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
