const {
  REACT_APP_API_URL,
  REACT_APP_APP_URL,
  REACT_APP_SITE_URL,
  REACT_APP_VERCEL_BRANCH_URL,
  REACT_APP_CDN_URL,
  REACT_APP_IMAGES_BUCKET_URL,
  REACT_APP_STRIPE_PK,
  REACT_APP_VERCEL_ENV,
  REACT_APP_ROOT_DOMAIN,
  REACT_APP_MIXPANEL_TOKEN,
  REACT_APP_MIXPANEL_API_HOST,
  REACT_APP_SANITY_API_VERSION,
  REACT_APP_SANITY_PROJECT_ID,
  REACT_APP_SANITY_DATASET,
  REACT_APP_SANITY_USE_CDN,
} = process.env;

export const apiUrl = REACT_APP_API_URL;
export const mailTemplatesUrl = REACT_APP_API_URL.replace('/api/v1', '/mail-templates');

function getUrl(url) {
  if (url.startsWith('https://') || url.startsWith('http://')) {
    return url;
  }

  return `https://${url}`;
}

let appUrl;
if (REACT_APP_VERCEL_ENV === 'production') {
  appUrl = getUrl(REACT_APP_APP_URL);
} else {
  appUrl = getUrl(REACT_APP_VERCEL_BRANCH_URL || REACT_APP_APP_URL);
}
export { appUrl };

export const siteUrl = REACT_APP_SITE_URL;

export const cdnUrl = REACT_APP_CDN_URL;
export const imagesBucketUrl = REACT_APP_IMAGES_BUCKET_URL;
export const stripePublicKey = REACT_APP_STRIPE_PK;

export const mixpanelToken = REACT_APP_MIXPANEL_TOKEN;
export const mixpanelApiHost = REACT_APP_MIXPANEL_API_HOST;

export const isProduction = REACT_APP_VERCEL_ENV === 'production';
export const isDevelopment = process.env.NODE_ENV === 'development';
export const rootDomain = REACT_APP_ROOT_DOMAIN;

export const apiVersion = REACT_APP_SANITY_API_VERSION;
export const dataset = REACT_APP_SANITY_DATASET;
export const projectId = REACT_APP_SANITY_PROJECT_ID;
export const useCdn = REACT_APP_SANITY_USE_CDN;
