import React from 'react';
import { Route, Switch } from 'react-router-dom';

const Store = React.lazy(() => import('./store'));
const Dashboard = React.lazy(() => import('./dashboard'));
const Unauthorized = React.lazy(() => import('./unauthorized'));

import NotFound from '../scenes/NotFound';
import { getStoreDomain } from '../lib/getStoreDomain';
import PublicLayout from '../layouts/public/PublicLayout';

const PublicRoute = ({ component: Component, ...rest }) => (
  <PublicLayout {...rest}>
    <Route {...rest} render={matchProps => <Component {...matchProps} />} />
  </PublicLayout>
);

const renderRoutes = () => {
  const storeDomain = getStoreDomain(window.location.hostname);

  if (storeDomain) {
    return (
      <React.Suspense fallback={null}>
        <Route
          path="/"
          component={props => <Store {...props} subdomain={storeDomain} />}
        />
      </React.Suspense>
    );
  }

  return (
    <React.Suspense fallback={null}>
      <Switch>
        <Route path="/store" component={Store} />

        <Route
          path={[
            '/c',
            '/checkout',
            '/s',
            '/subscriptions',
            '/overlay',
            '/embed',
          ]}
          component={Store}
        />

        <Route
          path={['/login', '/signup', '/account', '/accept-invite']}
          component={Unauthorized}
        />

        <Route path="/dashboard" component={Dashboard} />

        <PublicRoute component={NotFound} />

        <PublicRoute path="/404" component={NotFound} />
      </Switch>
    </React.Suspense>
  );
};

export default renderRoutes;
