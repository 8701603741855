import React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import insertScript from '../../lib/insertScript';
import Meta from '../../components/Meta';
import { tracking, useTracking } from '../../lib/tracking';

const PublicLayout = ({ children }) => {
  const location = useLocation();
  useTracking();

  useEffect(() => {
    tracking.trackPageview();
  }, [location]);

  useEffect(() => {
    function loadCrisp() {
      insertScript(
        `window.$crisp=[];window.CRISP_WEBSITE_ID="abe8e9e1-02b6-4715-b289-7573fd280b36";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`,
      );
    }

    if (!window.$crisp) {
      loadCrisp();
    }
  }, []);

  return (
    <div className="max-w-screen-2xl mx-auto">
      <Meta
        meta={{
          description:
            'Checkout pages and forms to sell your products and services. Installed in minutes without coding. Sell products, subscriptions, digital downloads, featured listings, event tickets and more.',
          url: 'https://checkoutpage.co',
          image: 'https://checkoutpage.co/assets/media-card.png',
          title: 'Checkout Page',
        }}
      />

      {children}
    </div>
  );
};

export default PublicLayout;
