import React from 'react';
import PropTypes from 'prop-types';

import Link from '../../components/Link';
import Meta from '../../components/Meta';

const propTypes = {
  location: PropTypes.object.isRequired,
};

const NotFound = () => {
  const meta = {
    title: '404 Not Found – Checkout Page',
    url: 'https://checkoutpage.co/404',
    description: 'Page not found',
  };

  return (
    <div className="text-center">
      <Meta meta={meta} />
      <div className="h-32" />
      <span role="img" aria-label="shrug" className="text-3xl">
        🤷‍♀️
      </span>
      <h1 className="mt-4">Page not found</h1>
      <p className="mt-4 leading-loose text-gray-600 text-lg">
        It is gone or may have never existed.
        <br />
        Nevertheless, you have proven its current nonexistence.
        <br />
        <br />
        Going back{' '}
        <Link to="/" color="default">
          Home{' '}
          <span role="img" aria-label="House">
            🏡
          </span>
        </Link>{' '}
        is probably a good idea.
        <br />
        <br />
        Need help?{' '}
        <Link to="/contact" color="default">
          Get in touch
        </Link>
        .
      </p>

      {/* <p className="mt-4 leading-loose text-gray-500 text-lg text-left">
        👴🏼<br /><br />
        Hi hi hi hi, who have we got here..
        <br /><br />
        You wandered into the forest of lost pages..
        <br /><br />
        <div>🌲🌲🌲🌲🌲🌲🌲🌲🌲</div>
        <div className="ml-8">🌲🌲🌲🌲🌲🌲</div>
        <div>🌲🌲🌲🌲🌲🌲🌲🌲🌲</div>
        <br />
        But don't worry, my friend. There's room here for you too..
        <br /><br />
      </p>

      <p className="mt-4 leading-loose text-gray-600 text-lg text-right">
        Psst.. don't trust the talking forest. You'd better go <Link to="/" styled>home 🏡</Link>, quick!
      </p> */}
    </div>
  );
};

NotFound.propTypes = propTypes;

export default NotFound;
