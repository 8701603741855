import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const propTypes = {
  meta: PropTypes.object.isRequired,
};

const Meta = ({ meta, children }) => (
  <Helmet>
    {meta.title ? <title>{meta.title} - Checkout Page</title> : ''}
    {meta.title ? <meta property="og:title" content={meta.title} /> : ''}
    {meta.title ? <meta name="twitter:title" content={meta.title} /> : ''}

    <link
      rel="icon"
      href="https://checkoutpage.co/assets/logo.png"
      key="favicon"
    />

    <link
      rel="apple-touch-icon"
      href="https://checkoutpage.co/assets/logo.png"
    />

    <link
      rel="mask-icon"
      href="https://checkoutpage.co/assets/logo.png"
      color="black"
    />

    {meta.description ? (
      <meta name="twitter:description" content={meta.description} />
    ) : (
      ''
    )}
    {meta.description ? (
      <meta property="og:description" content={meta.description} />
    ) : (
      ''
    )}
    {meta.description ? (
      <meta name="description" content={meta.description} />
    ) : (
      ''
    )}

    {meta.url ? <meta property="og:url" content={meta.url} /> : ''}
    {meta.url ? <meta name="twitter:url" content={meta.url} /> : ''}

    {Object.prototype.hasOwnProperty.call(meta, 'image') ? (
      <meta property="og:image" content={meta.image} />
    ) : (
      ''
    )}

    {children}
  </Helmet>
);

Meta.propTypes = propTypes;

export default Meta;
